import configService from '@/services/configService';
import dchAPI from '@/services/dchAPI';

const state = {
  sources: [],
};

const getters = {
  allSources: (state) => state.sources.sort((a, b) => a.name.localeCompare(b.name)),
  oneSource: (state) => (id) => {
    return state.sources.find((source) => source.id == id);
  },
  sourcesByAsset: (state) => (asset) => {
    return state.sources.filter((source) =>
      asset.sources.some((assetSource) => assetSource.handle === source.handle)
    );
  },
  enabledSources: (state) => {
    return state.sources.filter((source) => source.enabled);
  },
};

const actions = {
  async fetchDataFetchesBySource({ commit }, sourceHandle) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`data/fetch?limit=10&source=${sourceHandle}`)
        .then((response) => {
          const lastFetchFailed =
            response.data.data.length > 0 && !!response.data.data[0].progress.failed;
          commit('setLastFetchFailed', { sourceHandle, lastFetchFailed });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchSourcesData({ commit }) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get('sources')
        .then((response) => {
          const responseData = process.env.VUE_APP_IS_DEMO
            ? addAllSources(response.data.data)
            : response.data.data.map((r) => (r = transformSourcesResponseData(r)));
          commit('setSourcesData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchSourceData({ commit }, id) {
    return new Promise((resolve, reject) => {
      dchAPI
        .get(`sources/${id}`)
        .then((response) => {
          const responseData = (
            process.env.VUE_APP_IS_DEMO ? addAllSources([response.data.data]) : [response.data.data]
          ).map((r) => (r = transformSourcesResponseData(r)));
          commit('updateSource', responseData.filter((r) => r.id === id)[0]);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchSourceAndRelatedData({ dispatch }, id) {
    return Promise.all([
      dispatch('fetchSourceData', id),
      dispatch('fetchAssetsBySource', id),
      dispatch('fetchOutcomesBySource', id),
      dispatch('fetchTagsBySource', id),
    ]);
  },
  async updateSource({ commit }, model) {
    const updatedSource = {
      name: model.name,
      description: model.description,
      enabled: model.enabled,
      api_key: model.api_key,
      username: model.username,
      password: model.password,
    };
    const updatedSourceFiltered = Object.keys(updatedSource)
      .filter((k) => updatedSource[k] != null)
      .reduce((a, k) => ({ ...a, [k]: updatedSource[k] }), {});
    return new Promise((resolve, reject) => {
      dchAPI
        .put(`sources/${model.id}`, updatedSourceFiltered)
        .then((response) => {
          const responseData = {
            ...response.data,
            id: response.data.handle,
            active: false,
            clicked: false,
            not_connected: false,
            central: false,
            hidden: false,
            show_only_connected_to: false,
          };
          commit('updateSource', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async newSource({ commit }, model) {
    const newSource = {
      handle: model.id,
      name: model.name,
      description: model.description,
      username: model.username,
      password: model.password,
      api_key: model.api_key,
    };
    const newSourceFiltered = Object.keys(newSource)
      .filter((k) => newSource[k] != null)
      .reduce((a, k) => ({ ...a, [k]: newSource[k] }), {});

    return new Promise((resolve, reject) => {
      if (!newSource.api_key && (!newSource.username || !newSource.password)) {
        reject('Please provide either an API key or username and password');
        return;
      }

      dchAPI
        .post('sources', newSourceFiltered)
        .then((response) => {
          const responseData = {
            ...response.data,
            id: response.data.handle,
            active: false,
            clicked: false,
            not_connected: false,
            central: false,
          };
          commit('newSource', responseData.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async updateSourceConfig({ commit }, model) {
    const updatedSourceConfig = {
      config: model.config,
    };
    return new Promise((resolve, reject) => {
      dchAPI
        .put(`sources/${model.handle}`, updatedSourceConfig)
        .then((response) => {
          const responseData = {
            ...response.data.data,
            id: response.data.data.handle,
            active: false,
            clicked: false,
            not_connected: false,
            central: false,
          };
          commit('updateSource', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setSourcesData: (state, sources) => {
    state.sources = sources;
  },
  updateSource: (state, updatedSource) => {
    state.sources = state.sources.map((source) => {
      if (source.id === updatedSource.id) return updatedSource;
      else {
        return source;
      }
    });
  },
  deleteSource: (state, id) => {
    state.sources = state.sources.filter((source) => source.id != id);
  },
  newSource: (state, newSource) => {
    state.sources = [...state.sources, newSource];
  },
  setLastFetchFailed: (state, { sourceHandle, lastFetchFailed }) => {
    const index = state.sources.findIndex((s) => s.id === sourceHandle);
    if (index > -1) {
      state.sources[index].last_fetch_successful = !lastFetchFailed;
    }
  },
};

const transformSourcesResponseData = (data) => {
  const tData = {
    ...data,
    id: data.handle,
    active: false,
    clicked: false,
    not_connected: false,
    central: false,
    is_enabled: true,
    hidden: false,
    show_only_connected_to: false,
    enabled: process.env.VUE_APP_IS_DEMO ? true : data.enabled,
  };
  return tData;
};

const addAllSources = (data) => {
  const tData = data.map((r) => (r = transformSourcesResponseData(r)));
  let availableSources = configService
    .availableSources()
    .map(
      (s) =>
        (s = {
          ...tData.find((cs) => cs.id === s.id),
          ...s,
          active: true,
          clicked: false,
          not_connected: false,
          central: false,
          is_enabled: true,
          enabled: true,
          hidden: false,
          assets: [],
          tags: [],
          outcomes: [],
          last_fetch_successful: true,
        })
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  return availableSources;
};

export default {
  state,
  getters,
  actions,
  mutations,
};
